<template>
  <v-container class="page-login" fill-height>
    <v-row>
      <v-col>
        <v-card class="pa-3 page-login__card" tile>
          <v-card-title>
            <h1 class="primary--text display-1 page-login_title">
              Murphy
            </h1>
          </v-card-title>
          <v-card-text>
            <v-alert type="info">
              Bienvenido a Murphy Pacífico
            </v-alert>
            <v-form
              ref="form"
              class="my-10"
              lazy-validation
              v-model="formValid"
            >
              <v-text-field
                append-icon="mdi-email"
                autocomplete="off"
                name="login"
                :label="$vuetify.lang.t('$vuetify.login.email')"
                :placeholder="$vuetify.lang.t('$vuetify.login.email')"
                type="text"
                required
                outlined
                :rules="formRule.email"
                v-model="formModel.email"
              />
              <v-text-field
                append-icon="mdi-lock"
                autocomplete="off"
                name="password"
                :label="$vuetify.lang.t('$vuetify.login.password')"
                :placeholder="$vuetify.lang.t('$vuetify.login.password')"
                type="password"
                :rules="formRule.password"
                required
                outlined
                v-model="formModel.password"
                v-on:keyup.enter="login"
              />
              <v-switch
                v-model="formModel._undefined"
                label="Mantener sesión"
              ></v-switch>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn large tile color="primary" @click="login" :loading="loading">
              {{ $vuetify.lang.t('$vuetify.login.login') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'PageLogin',
  data() {
    return {
      loading: false,
      formValid: false,
      formModel: {
        email: null,
        password: null,
        _undefined: true
      },
      formRule: {
        email: [
          (v) => !!v || this.$vuetify.lang.t('$vuetify.rule.required', ['email']),
        ],
        password: [
          (v) => !!v || this.$vuetify.lang.t('$vuetify.rule.required', ['password']),
        ],
      },
    };
  },
  computed: {
    prefix() {
      return '';
    },
  },
  methods: {
    login() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.$store
          .dispatch('auth/login', this.formModel)
          .then(() => {
            this.$router.push('/home');
          })
          .catch((err) => {
            console.error({ err })
            this.loading = false;
          });
      }
    },
    handleSocialLogin() {},
  },
};
</script>

<style lang="sass" scoped>
.page-login
  &__card
  max-width: 600px
  margin: 0 auto
</style>
